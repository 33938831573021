import React from 'react';
import { BlogPostData } from '../../types';

import BlogTile from '../blog-tile';
import './blog-page-tiles.css';

type BlogPageTilesProps = {
    posts: {
        node: BlogPostData;
    }[];
    searchActive: boolean;
};

const BlogPageTiles: React.FC<BlogPageTilesProps> = ({ posts, searchActive }) => {
    const sortedPostsBasedOnDate = posts.sort((a, b) => Date.parse(b.node.createdAt) - Date.parse(a.node.createdAt));

    // Apply a class to the wrapper if search is active
    const wrapperClass = searchActive ? "blog-page-tiles-wrapper search-active" : "blog-page-tiles-wrapper";

    return (
        <section className="blog-page-tiles-section">
            <div className="container">
                <div className={wrapperClass}>
                    {sortedPostsBasedOnDate?.map((post: { node: BlogPostData }, i: number) => (
                        <BlogTile
                            key={`blog-post-${i}`}
                            to={post.node.slug}
                            title={post.node.title}
                            image={post.node.image.fluid}
                            author={post.node.author.name}
                            date={post.node.createdAt}
                            highlight={!searchActive && i === 0}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BlogPageTiles;
