import React, { useState } from 'react';
import { graphql } from 'gatsby';

import HtmlHead from '../components/html-head';
import Navbar from '../components/navbar';
import BlogHero from '../components/blog-hero';
import BlogPageTiles from '../components/blog-page-tiles';
import Footer from '../components/footer';
import { BlogPostData } from '../types';

import '../styles/index.css';
import '../styles/blog.css';

type BlogPageProps = {
    data: {
        allContentfulPageSeoData: {
            edges: {
                node: {
                    title: string;
                    canonicalLink: string;
                    metaDescription: {
                        metaDescription: string;
                    };
                    openGraphImage: {
                        file: {
                            url: string;
                        };
                    };
                };
            }[];
        };
        allContentfulBlogPosts: {
            edges: {
                node: BlogPostData;
            }[];
        };
    };
};

const BlogPage: React.FC<BlogPageProps> = ({ data }) => {
    const seoData = data.allContentfulPageSeoData.edges[0].node;
    const [filteredPosts, setFilteredPosts] = useState(data.allContentfulBlogPosts.edges);
    const [query, setQuery] = useState('');

    const handleSearch = (searchQuery: string) => {
        setQuery(searchQuery);
        if (searchQuery.trim() === '') {
            setFilteredPosts(data.allContentfulBlogPosts.edges);
        } else {
            const filtered = data.allContentfulBlogPosts.edges.filter(({ node }) =>
                node.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredPosts(filtered);
        }
    };

    return (
        <>
            <HtmlHead
                title={seoData.title}
                metaDescription={seoData.metaDescription.metaDescription}
                canonicalLink={seoData.canonicalLink}
                openGraphImage={seoData.openGraphImage.file.url}
            />
            <Navbar />
            <div className="blog-page-bg">
                <BlogHero onSearch={handleSearch} />
                <BlogPageTiles posts={filteredPosts} searchActive={query.trim() !== ''} />
                <Footer />
            </div>
        </>
    );
};

export default BlogPage;

export const query = graphql`
    query BlogQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/blog" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
        allContentfulBlogPosts {
            edges {
                node {
                    slug
                    title
                    createdAt(formatString: "MMM[.] DD[,] YYYY")
                    image {
                        fluid(maxHeight: 540, quality: 100) {
                            base64
                            aspectRatio
                            srcSetWebp
                            srcWebp
                            sizes
                        }
                    }
                    author {
                        name
                    }
                }
            }
        }
    }
`;
