import React from 'react';
import './blog-hero.css';

const BlogHero: React.FC<{ onSearch: (query: string) => void }> = ({ onSearch }) => {
    const [query, setQuery] = React.useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        onSearch(value);  
    };

    return (
        <section className="hero-section blog-hero-section">
            <div className="container">
                <div className="blog-hero-wrapper">
                    <h1 className="text-3xl bold teal animate blog-h1">AptEdge Insights</h1>
                    <p className="text-lg text-black description animate">
                        Take a deeper dive into the future of GPT powered Customer Care with webinars, blogs, events, and more.
                    </p>
                    <div className="animate form-parent">
                        <input
                            type="text"
                            placeholder="Search blogs..."
                            value={query}
                            onChange={handleInputChange}
                            className="blog-search-input"
                        />
                        <button onClick={() => onSearch(query)} className="blog-search-button">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogHero;
